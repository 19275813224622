<template>
    <div class="container_file">
        <!-- <embed :src="file" class="file"> -->
        <span v-html="fileRender" />
    </div>
</template>

<script>

export default {
  data: () => ({
    file: JSON.parse(localStorage.getItem('fileDataView')),
    fileRender: '',
    /*
    file: '',
    fileRender: '',
    fileD: JSON.parse(localStorage.getItem('fileDataView')),
    fileId: '',
    */
  }),
  mounted() {
    this.getDataUser()
  },
  methods: {
    async getDataUser() {
      const result = this.file.data
      const fileResult = `${result.dataType},${result.bytes}`
      this.renderFile(result.dataType, fileResult)

      this.file = fileResult
    },

    renderFile(str, fileResult) {
      if (str.search(/jpg|jpeg|png/) > 0) {
        const r = `<img src='${fileResult}' class="img_position">`
        this.fileRender = r
      } else if (str.search(/pdf/) > 0) {
        const r = `<embed src='${fileResult}' type='application/pdf' class="pdf_size"/>`
        this.fileRender = r
      } else {
        this.downloadItem()
      }
    },
    downloadItem() {
      const blob = new Blob([this.file.data.bytes], { type: this.file.data.dataType })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.file.data.name
      link.click()
      URL.revokeObjectURL(link.href)

    }
  },
}
</script>
<style>
.container_file {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow-y: overlay;
    /* padding-bottom: 56.25%; */
}
.file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.img_position {
  position: fixed;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  max-width: 100%;
  max-height: 100%;
}
.pdf_size {
  height: 100vh;
  width: 100%;
}
</style>
